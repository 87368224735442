
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import BackendEntry from '../../components/Backend/Entry/BackendEntry.vue';

export default defineComponent({
  components: {
    BackendEntry,
  },
  setup() {
    const store = useStore();
    const entries = ref<Array<i18nEntry>>([]);

    const load = () => {
      store.dispatch('fetchEntries').then((res: Array<i18nEntry>) => {
        entries.value = res.sort((a: i18nEntry, b: i18nEntry) => {
          if (a.publishdate > b.publishdate) return -1;
          if (a.publishdate < b.publishdate) return 1;
          return 0;
        });
      });
    };

    load();

    return {
      entries,
      load,
    };
  },
});
