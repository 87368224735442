
import { defineComponent, computed, PropType, ref } from 'vue';

import dayjs from 'dayjs';
import { useStore } from 'vuex';

import { mdiPencil, mdiDelete, mdiEyeOff } from '@mdi/js';

type i18nEntryWithId = i18nEntry & { id: number };

export default defineComponent({
  props: {
    entry: { type: Object as PropType<i18nEntryWithId>, required: true },
    lang: { type: String as PropType<'en' | 'de'>, default: 'de' },
  },
  emits: ['deleted'],
  setup(props, { emit }) {
    const publishdate = computed(() => {
      if (props.entry.publishdate !== null) {
        return dayjs(props.entry.publishdate)
          .locale(props.lang)
          .format('DD. MMMM YYYY HH:MM');
      } else {
        return '';
      }
    });

    const localentry = computed(() => {
      if (props.entry.de) return props.entry.de;
      if (props.entry.en) return props.entry.en;
      return undefined;
    });

    const keywords = computed(() => localentry.value?.keywords.toString().split(','));

    const store = useStore();

    const confirmdelete = ref(false);
    const onDeleteClick = (id: number) => {
      if (confirmdelete.value) {
        store.dispatch('deleteEntry', id).then(() => {
          store.dispatch('addNotification', { message: 'Beitrag gelöscht.' });
          emit('deleted');
        });
      } else {
        confirmdelete.value = true;
        setTimeout(() => (confirmdelete.value = false), 4000);
      }
    };

    return {
      publishdate,
      localentry,
      keywords,
      confirmdelete,
      mdiPencil,
      mdiDelete,
      mdiEyeOff,
      onDeleteClick,
    };
  },
});
