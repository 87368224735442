<style lang="scss">
#backend {
  .overview-entry {
    //border:1px solid red;
    .col-lg-4 {
      margin-bottom: 30px;
    }
  }
}
</style>

<template>
  <div class="container-fluid overview-entry">
    <div class="row justify-content-center">
      <BackendEntry :entry="entry" :key="entry.id" v-for="entry in entries" @deleted="load" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import BackendEntry from '../../components/Backend/Entry/BackendEntry.vue';

export default defineComponent({
  components: {
    BackendEntry,
  },
  setup() {
    const store = useStore();
    const entries = ref<Array<i18nEntry>>([]);

    const load = () => {
      store.dispatch('fetchEntries').then((res: Array<i18nEntry>) => {
        entries.value = res.sort((a: i18nEntry, b: i18nEntry) => {
          if (a.publishdate > b.publishdate) return -1;
          if (a.publishdate < b.publishdate) return 1;
          return 0;
        });
      });
    };

    load();

    return {
      entries,
      load,
    };
  },
});
</script>
