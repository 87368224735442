<style lang="scss" scoped>
.control-box-outer {
  //border:1px solid red;
  display: flex;
  .control-box {
    margin-bottom: 5px;
    .btn {
      border: 1px solid #eee;
      margin-right: 5px;
      svg {
        width: 20px;
        height: 20px;
        fill: #888;
      }
      &:hover {
        svg {
          fill: #333;
        }
      }
    }
    .controllabel {
      display: inline-block;
      background-color: #eee;
      padding: 5px 13px;
      line-height: 30px;
      margin-right: 5px;
      border-radius: 5px;
      letter-spacing: 1px;
      font-size: 80%;
      color: #333;
      font-family: 'Helvetica Neue LT W05 65 Medium';
    }
  }
}
.entry-box {
  position: relative;

  @media (max-width: $width-lg) {
    margin: 15px auto;
  }
  .mediacontainer {
    padding: 0 !important;
  }
  .img-box {
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .txt-box {
    padding: 40px 50px;
    border: 1px solid #cfcfcf;
    a {
      display: block;
      &:hover {
        color: #000;
        opacity: 0.7;
      }
    }
    .date {
      font-family: 'Helvetica Neue LT W05 65 Medium';
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 2px;
      font-weight: 500;
    }
    h2 {
      font-family: 'Helvetica Neue LT W05_75 Bold';
      margin-bottom: 20px;
      line-height: 1.3;
      font-size: 1.6rem;
      text-transform: none;

      @media (max-width: $width-xl) {
        font-size: 140%;
      }
    }
    p {
      //font-family:"Helvetica Neue LT W05 35 Thin";
      line-height: 1.8;
      letter-spacing: 1px;
    }
    .keywords {
      margin-top: 30px;
      margin-bottom: 30px;
      span {
        display: inline-block;
        margin-right: 5px;
        a {
          font-family: 'Helvetica Neue LT W05 45 Light';
          display: inline-block;
          color: #ccc;
          margin-right: 5px;
          font-size: 90%;
          letter-spacing: $letter-spacing-std;

          &:hover {
            color: #000;
          }
        }
      }
    }
  }

  &:hover {
    .img-box {
      opacity: 0.7;
      transition: $transition-std;
    }
  }
}
</style>

<template>
  <div class="col-lg-4 col-md-6 col-sm-8">
    <div class="control-box-outer">
      <div class="control-box" v-hasperm="'entry.edit'">
        <router-link tag="div" class="btn btn-edit" target="tooltip-target-1" triggers="hover" :to="{ name: 'EditEntry', params: { id: entry.id } }"><Icon :path="mdiPencil"/></router-link>
      </div>
      <div class="control-box" v-hasperm="'entry.edit'">
        <div class="btn btn-delete" @click="onDeleteClick(entry.id)"><span v-if="confirmdelete">Wirklich löschen? </span><Icon :path="mdiDelete" /></div>
      </div>
      <div class="control-box">
        <div class="controllabel" v-if="entry.de">DE</div>
        <div class="controllabel" v-if="entry.en">EN</div>
        <div class="controllabel" v-if="!entry.visible"><Icon :path="mdiEyeOff" /></div>
      </div>
    </div>
    <!-- <ControlEntryOverview :entry="entry.id" v-if="controls && entry.id" @deleted="$emit('deleted')" /> -->
    <div class="entry-box" v-if="localentry">
      <div class="img-box">
        <Media :media="entry.thumbnail" imagesize="500xauto" />
      </div>
      <div class="txt-box">
        <div class="date">{{ publishdate }}</div>
        <h2>{{ localentry.title }}</h2>
        <p>{{ localentry.introtext }}</p>
        <div class="keywords">
          <span :key="keyword" v-for="keyword in keywords"
            ><router-link :to="{ name: 'Home' }">#{{ keyword }}</router-link></span
          >
        </div>
        <!--<div class="langlabels">
          <div class="langlabel" v-if="entry.de">
            DE
          </div>
          <div class="langlabel" v-if="entry.en">
            EN
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref } from 'vue';

import dayjs from 'dayjs';
import { useStore } from 'vuex';

import { mdiPencil, mdiDelete, mdiEyeOff } from '@mdi/js';

type i18nEntryWithId = i18nEntry & { id: number };

export default defineComponent({
  props: {
    entry: { type: Object as PropType<i18nEntryWithId>, required: true },
    lang: { type: String as PropType<'en' | 'de'>, default: 'de' },
  },
  emits: ['deleted'],
  setup(props, { emit }) {
    const publishdate = computed(() => {
      if (props.entry.publishdate !== null) {
        return dayjs(props.entry.publishdate)
          .locale(props.lang)
          .format('DD. MMMM YYYY HH:MM');
      } else {
        return '';
      }
    });

    const localentry = computed(() => {
      if (props.entry.de) return props.entry.de;
      if (props.entry.en) return props.entry.en;
      return undefined;
    });

    const keywords = computed(() => localentry.value?.keywords.toString().split(','));

    const store = useStore();

    const confirmdelete = ref(false);
    const onDeleteClick = (id: number) => {
      if (confirmdelete.value) {
        store.dispatch('deleteEntry', id).then(() => {
          store.dispatch('addNotification', { message: 'Beitrag gelöscht.' });
          emit('deleted');
        });
      } else {
        confirmdelete.value = true;
        setTimeout(() => (confirmdelete.value = false), 4000);
      }
    };

    return {
      publishdate,
      localentry,
      keywords,
      confirmdelete,
      mdiPencil,
      mdiDelete,
      mdiEyeOff,
      onDeleteClick,
    };
  },
});
</script>
